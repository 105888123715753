import React, { useState } from 'react';

import './enquiry-form.scss';
import { Field, Form, Formik } from 'formik';

export const EnquiryForm = () => {
  const [formSending, setFormSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const initialValues = {
    name: "",
    mobile: "",
    description: "",
  }
  const onSubmit = (data: any) => {
    var formData = new FormData();

    for ( var key in data ) {
      formData.append(key, data[key]);
    }
    setFormSending(true);
    fetch('/api/talk.php', {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With' : 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(response => {
      setSuccessMessage(response.message);
      setFormSending(false);
      document.getElementById('enquiryForm').reset();
    })
    .catch(error => {
      setFormSending(false);
      setErrorMessage(error.message);
    })
  }

  return (
    <div id="enquiry" className="enquiry-container mb-5">
      <h1 className="pt-0">Enquiry Form</h1>
      <p>How can we help?</p>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form id="enquiryForm">
          <div className="mb-3">
            <Field id="name" name="name" placeholder="Name" type="text" className="form-control" required />
          </div>
          <div className="mb-3">
            <Field id="mobile" name="mobile" placeholder="Mobile" type="number" className="form-control" required />
          </div>
          <div className="mb-3">
            <Field
              component="textarea"
              id="description"
              name="description"
              className="form-control"
              placeholder="Your Message"
              required
            ></Field>
          </div>
          {successMessage && <div id="emailSuccess" className="alert alert-primary" role="alert">
            Enquiry sent successfully!
          </div>}
          {errorMessage && <div id="emailError" className="alert alert-danger" role="alert">
            An unknown error occurred.
          </div>}
          <div className="mb-3 d-flex">
            <button disabled={formSending} type="submit" className="btn btn-primary me-2">
              Submit
            </button>
            {formSending && <div id="formSending" className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
          </div>
        </Form>
      </Formik>
    </div>
  );
};
