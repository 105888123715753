import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { EnquiryForm } from '../components/enquiry-form';
import Layout from '../components/layout';

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact us" title="MRC Agrotech Ltd - Contact us">
      <>
        <div className="text-center" data-sal="zoom-in" data-sal-easing="ease-out">
          <StaticImage className="mb-3" src="../images/mrc-logo-before.png" width={300} height={203} alt="MRC Logo" />
        </div>
        <EnquiryForm data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out"></EnquiryForm>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 text-center" data-sal="fade" data-sal-delay="150" data-sal-easing="ease-out">
            <h2>Registered Office</h2>
            <p>
              Office No. 1028, 10th Floor,
              <br />
              The Summit - Business Bay Omkar Near Western Express Highway,
              <br />
              Andheri East., Mumbai - 400093
              <br />
              <strong>Email</strong>: <a href="mailto:info@mrcagro.com">info@mrcagro.com</a>
            </p>
          </div>
          <div className="col-12">
            <iframe
              className="map-area"
              src="https://www.google.com/maps/d/embed?mid=1pyXorAo_LgPXFwxURfg43OE_gFIfspgs&hl=en&ehbc=2E312F"
              width="100%"
              height="480"
            ></iframe>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default ContactPage;
